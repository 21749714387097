:root {
  --fg: #08080d;
  --bg: #ffffff;

  --blue: #7552ff;
  --yellow: #fff0a3;
  --orange: #fc7d3b;

  --accent: var(--blue);
  --accent-fg: var(--white);

  --link-color: inherit;

  --errors-fg: hsl(0, 100%, 27%);

  --menu-bg: var(--black);
  --menu-fg: var(--white);
  --menu-button-bg: var(--blue);
  --menu-button-color: var(--white);

  --header-bg: var(--black);
  --header-color: var(--white);

  --hero-color: var(--white);

  --faq-color: var(--bg);
}

body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

h1,
h2,
h3 {
  font-family: ArchivoNarrow, Helvetica, Arial, sans-serif;
}

.button {
  font-family: EncodeSans, Helvetica, Arial, sans-serif;
  font-weight: 400;
}

.button--accent {
  --_bg: var(--button-bg, var(--fg));
  --_fg: var(--button-color, var(--bg));
}

.button--accent:hover {
  color: var(--_bg);
  background: var(--_fg);
}

.engagement-box:nth-child(3n + 1) {
  --fg: var(--white);
  --button-color: var(--black);
}

.engagement-box:nth-child(3n + 2) {
  --accent: var(--orange);
  --fg: var(--white);
  --button-color: var(--black);
}

.engagement-box:nth-child(3n + 3) {
  --accent: var(--yellow);
  --fg: var(--black);
  --button-bg: var(--black);
  --button-color: var(--white);
}

.engagement-box.accented {
  input {
    --fg: var(--black);
  }
}

/* hide italian */
.language-nav .nav-list-item:last-child {
  display: none;
}

.hero.hero--top .logo-image {
  opacity: 1;
}

.testimonial__box {
  color: var(--fg);
  background-color: transparent;
  outline: 2px solid var(--fg);
}

.plugin--pledge-teaser {
  background-image: url(../frontend/static/assets/butterfly.webp);
  background-size: cover;

  .button {
    --_bg: var(--button-bg, var(--bg));
    --_fg: var(--button-color, var(--fg));
  }
}
