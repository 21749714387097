@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  src:
    local("Roboto"),
    url(./Roboto.woff2) format("woff2");
  font-variation-settings: "wght" 400;
  font-display: swap;
}
