@font-face {
  font-family: ArchivoNarrow;
  font-weight: 400;
  font-style: normal;
  src:
    local("ArchivoNarrow-Regular"),
    url(../fonts/ArchivoNarrow/ArchivoNarrow-Regular.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: ArchivoNarrow;
  font-weight: 700;
  font-style: normal;
  src:
    local("ArchivoNarrow-Bold"),
    url(../fonts/ArchivoNarrow/ArchivoNarrow-Bold.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: ArchivoNarrow;
  font-weight: 400;
  font-style: italic;
  src:
    local("ArchivoNarrow-Italic"),
    url(../fonts/ArchivoNarrow/ArchivoNarrow-Italic.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: ArchivoNarrow;
  font-weight: 700;
  font-style: italic;
  src:
    local("ArchivoNarrow-BoldItalic"),
    url(../fonts/ArchivoNarrow/ArchivoNarrow-BoldItalic.woff2) format("woff2");
  font-display: swap;
}
